<template>
  <div class="projects-view">

    <h1 class="main-title projects-title">Projects</h1>

    <div class="actions-top">
      <!-- Search input -->
      <SearchInputField @search="searchForProject"
                        :includeSuggestions="false"
                        :result-count="searchTerm ? projectsArray.length : null"
                        placeholder="Search for a project"
                        id="jiraProject"
                        class="search"/>

      <!-- Create project button -->
      <DButton @click.native="$emit('create')"
               text="Add project"
               :icon="require('../../../assets/icons/svg/ic_add_white.svg')"
               type="button"
               class="button-main button-add"/>
    </div>

    <!-- Table with projects -->
    <CustomTable :options="tableOptions" :table-data="projectsArray" class="project-overview-table">
      <template #name="props">
        <div class="project-name">
          <span @click="redirectToOrder(props.rowData)" class="name">
            {{ props.rowData.name }}
          </span>
        </div>
      </template>
      <template #action="props">
        <div class="actions-wrapper">
          <!-- TODO Uncomment when the API is complete. -->
          <!--          <Chip v-if="true"-->
          <!--                @clicked="redirectToUnassigned(props.rowData)"-->
          <!--                :text="unassignedHoursChip.text"-->
          <!--                :custom-class="unassignedHoursChip.customClass"-->
          <!--                :icon="require('../../../assets/icons/svg/ic_alert.svg')"-->
          <!--                is-link-->
          <!--                class="hours-chip"/>-->

          <img @click="editProject(props.rowData)"
               src="../../../assets/icons/svg/ic_edit.svg"
               class="edit-row"
               alt="edit"/>
        </div>
      </template>
    </CustomTable>

    <!-- No results indication -->
    <p v-if="!projectsArray.length" class="no-results-table-text">There are no results for '{{ searchTerm }}'.</p>

    <!-- Pagination -->
    <Pagination v-if="paginationData && projectsArray.length > 0"
                :pagination-data="paginationData"
                :go-to-page="goToPage"/>
  </div>
</template>

<script>
import SearchInputField from "@/components/partials/search/SearchInputField";
import DButton from "@/components/elements/DButton";
import {computed, onUnmounted, ref, watch} from "@vue/composition-api";
import CustomTable from "@/components/partials/table/CustomTable";
import Pagination from "@/components/partials/pagination/Pagination";
import {RESET_PAGINATION_DATA} from "@/store/mutations";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import Chip from "@/components/elements/Chip";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";
import {ROUTE_NAMES_MANAGE_LOGS} from "@/router/modules/manageLogs";
import initializeProjectsTable from "@/composables/projects/getProjectsTable";

export default {
  name: "ProjectsView",
  components: {
    Chip,
    Pagination,
    CustomTable,
    DButton,
    SearchInputField
  },
  props: {
    projects: {
      type: Array,
      required: true
    },
  },
  emit: ['search', 'edit'],
  setup(props, {root, emit}) {
    const store = root.$store;
    const router = root.$router;

    /** Table **/
    const {tableOptions} = initializeProjectsTable();

    /** Edit **/
    function editProject(project) {
      emit('edit', project);
    }

    /** Redirections **/
    function redirectToOrder(project) {
      const projectId = project.id;
      router.push({name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW, params: {projectId}});
    }

    function redirectToUnassigned(project) {
      const projectId = project.id;
      router.push({name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS, params: {projectId}});
    }

    /** Projects data **/
    watch(() => props.projects, (newVal) => {
      projectsArray.value = newVal;
    });

    /** Search **/
    const projectsArray = ref(props.projects);
    const searchTerm = ref('');

    function searchForProject(searchedText) {
      searchTerm.value = searchedText;

      // Send the search term to the parent.
      emit('search', searchTerm.value);
    }

    /** Pagination **/
    const isPaginationDisplayed = computed(() => paginationData.value?.totalPages > 1 && projectsArray.value.length); // The pagination should be displayed if there are more than 2 pages.
    const paginationData = computed(() => store.getters.getPaginationData);

    function goToPage(page) {
      emit('search', searchTerm.value, page)
    }

    onUnmounted(() => {
      store.commit(RESET_PAGINATION_DATA);
    });

    return {
      unassignedHoursChip: new ChipOption('Unassigned hours', CHIP_STYLES.LIGHT_GRAY),

      /** Table **/
      tableOptions,

      /** Edit **/
      editProject,

      /** Redirections **/
      redirectToOrder,
      redirectToUnassigned,

      /** Search **/
      searchTerm,
      projectsArray,
      searchForProject,

      /** Pagination **/
      isPaginationDisplayed,
      paginationData,
      goToPage,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.projects-view {
  text-align: left;

  .projects-title {
    margin-bottom: rem(64);
  }
}

.actions-top {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(24);

  .search {
    flex-grow: 1;
  }

  .button-add {
    display: flex;
    flex-shrink: 0;
    height: rem(52);
    margin-left: rem(24);
    width: rem(170);
  }
}

// Table
.project-name {
  .name {
    color: var(--red-main);

    @include on-hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// Edit
.actions-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .hours-chip {
    @include hover-active-pointer;
    flex-shrink: 0;
    margin-right: rem(15);
  }

  .edit-row {
    @include hover-active-pointer();
    height: rem(14);
    width: rem(14);
  }
}
</style>
