<template>
  <div class="page-wrapper">

    <!-- Screen that is displayed when the user does not have any projects. -->
    <NoProjectsYet v-if="!projects.length && !isSearchActive" @create="toggleCreateModal"/>

    <!-- Screen that is displayed when the user has at least one project. -->
    <ProjectsView v-else :projects="projects"
                  @create="toggleCreateModal"
                  @search="getProjects"
                  @edit="toggleEditModal"
                  ref="projectsView"/>

    <!-- Create project modal -->
    <CreateProject v-if="activeModal === MODAL_NAMES.CREATE_PROJECT" @created="onProjectCreation"/>

    <!-- Edit project modal -->
    <EditProjectModal v-else-if="activeModal === MODAL_NAMES.EDIT_PROJECT"
                      @initialize-edit="toggleEditModal"
                      @edited="onProjectEdit"
                      @deleted="onProjectDeletion"
                      :project="selectedProject"/>

    <!-- Delete project modal -->
    <Modal v-else/>
  </div>
</template>

<script>
import {MODAL_CONTENT, MODAL_NAMES} from "../../models/ModalContent";
import NoProjectsYet from "@/components/partials/projectOverview/NoProjectsYet";
import {computed, ref} from "@vue/composition-api";
import ProjectsView from "@/components/partials/projectOverview/ProjectsView";
import CreateProject from "@/components/partials/createProject/CreateProject";
import {GET_ALL_PROJECTS} from "../../store/project/actions";
import Toast from "@/components/elements/Toast";
import BasicDatePicker from "@/components/elements/BasicDatePicker";
import EditProjectModal from "../../components/partials/createProject/EditProjectModal";
import Modal from "../../components/partials/Modal";
import {ERR_TYPE} from "@/utils/handlers/ApiErrorMsgHandler";
import {getModalHelpers} from "../../composables/modalHelper";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import {watch} from "@vue/composition-api";

export default {
  name: "ProjectOverview",
  components: {
    Modal,
    EditProjectModal,
    Toast,
    CreateProject,
    ProjectsView,
    NoProjectsYet,
    BasicDatePicker
  },
  setup(props, {root}) {
    const store = root.$store;

    const apiError = computed(() => store.getters.getApiError);
    watch(apiError, (newValue) => {
      if (newValue === ERR_TYPE.CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT) {
        ToastHandler.addNew(TOAST_CONTENTS.PROJECT_DELETE_UNALLOWED);
      }
    })

    /** Modal **/
    const {activeModal, setModal, closeModal} = getModalHelpers();

    /** Edit project **/
    const selectedProject = ref(null);

    function toggleEditModal(project) {
      selectedProject.value = project;
      setModal(MODAL_CONTENT.EDIT_PROJECT(null, cancelEdit));
    }

    function cancelEdit() {
      closeModal();
      resetSelectedProject();
    }

    function onProjectEdit() {
      // Reset modal and selected project.
      closeModal();
      resetSelectedProject();

      // Update the data.
      getProjects();
    }

    function resetSelectedProject() {
      selectedProject.value = null;
    }

    /** Delete project **/
    // We need a listener cause the edit modal closes after the delete warning modal pops up.
    // In result, the callback passed in the modal content would not be fired.
    // TODO: Improve the popups logic.
    document.addEventListener('project-deleted', (event) => {
      const {name} = event.detail;
      onProjectDeletion(name);
    });

    function onProjectDeletion() {
      // Reset edit project modal and selected project.
      closeModal();
      resetSelectedProject();
      getProjects();
    }

    /** Create project **/
    function toggleCreateModal() {
      setModal(MODAL_CONTENT.CREATE_PROJECT());
    }

    function onProjectCreation(projectName) {
      ToastHandler.addNew(TOAST_CONTENTS.PROJECT_CREATED(projectName));
      closeModal();
      getProjects();
    }

    /** Search **/
    const projectsView = ref(null);
    const isSearchActive = computed(() => !!projectsView.value?.searchTerm);

    /** Projects **/
    getProjects();
    const projects = computed(() => store.getters.getAllProjects);

    function getProjects(name = '', page = 1) {
      let text = isSearchActive.value ? projectsView.value.searchTerm : name;
      return store.dispatch(GET_ALL_PROJECTS, {name: text, page: page});
    }

    return {
      ERR_TYPE,
      apiError,
      MODAL_NAMES,

      /** Modal **/
      activeModal,

      /** Edit project **/
      selectedProject,
      toggleEditModal,
      onProjectEdit,

      /** Delete project **/
      onProjectDeletion,

      /** Create project **/
      onProjectCreation,
      toggleCreateModal,

      /** Search **/
      projectsView,
      isSearchActive,

      /** Projects **/
      getProjects,
      projects,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";


</style>
