export default function initializeProjectsTable() {
  const tableOptions = {
    headings: {
      'name': 'Project name',
      'action': ''
    },
    editable: {
      'name': false,
      'action': false,
    },
    sortable: {
      'name': false,
      'action': false,
    }
  }

  return {tableOptions};
}