<template>
  <div>
    <Modal @execute="getDataAndCreateProject" :disabled-right-button="!isCreateButtonEnabled">
      <template #content>
        <div class="create-section">
          <p class="section-title">Project details</p>

          <h3 class="basic-input-title input-title">Project name</h3>
          <label for="projectName" class="basic-label">
            <input type="text"
                   id="projectName"
                   name="projectName"
                   placeholder="e.g. Billy"
                   data-cy="project-name"
                   v-model="projectName"
                   class="basic-input"/>
          </label>
        </div>

        <div class="create-section">
          <p class="section-title">Assign Jira Project(s)</p>

          <!-- Search input with search suggestions -->
          <SearchInputField @search="search"
                            @select-item="selectJiraProject"
                            :search-results="searchResults"
                            auto-search
                            :close-after-selection="false"
                            include-suggestions
                            id="searchJiraProject"
                            placeholder="Search for a project"/>

          <!-- Selected Jira projects -->
          <div v-if="selectedJiraProjects.length" class="selected-projects">
            <ItemWithRemoveButton v-for="project in selectedJiraProjects"
                                  :key="project.id"
                                  :text="project.name"
                                  @remove="removeJiraProject"
                                  :project="project"
                                  class="project-item"/>
          </div>
          <p v-else class="no-selected-projects">No projects assigned yet</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import Modal from "@/components/partials/Modal";
import SearchInputField from "@/components/partials/search/SearchInputField";
import ItemWithRemoveButton from "@/components/partials/ItemWithRemoveButton";
import {GET_ORDER_TYPES} from "../../../store/order/actions";
import {SEARCH_JIRA_PROJECT} from "@/store/jira/actions";
import {CLEAR_JIRA_PROJECTS} from "@/store/jira/mutations";
import {CREATE_PROJECT} from "../../../store/project/actions";
import {isEmptyOrSpaces} from "@/utils/globals";
import getOrderHelpers from "../../../composables/order/getOrderHelpers";
import getJiraHelpers from "../../../composables/jira/getJiraHelpers";

export default {
  name: "CreateProject",
  components: {
    ItemWithRemoveButton,
    SearchInputField,
    Modal,
  },
  emits: ['created'],
  setup(props, {root, emit}) {
    const store = root.$store;

    /** Project name **/
    const projectName = ref('');

    /** Search Jira projects **/
    const selectedJiraProjects = ref([]);

    function selectJiraProject(item) {
      // Add the clicked item to the selected jira projects.
      selectedJiraProjects.value.push(item);

      // Since the search results panel remains open after selection, the selected project should be removed from the results.
      searchResults.value = searchResults.value.filter(item => !item.isInArray(selectedJiraProjects.value));
    }

    function removeJiraProject(item) {
      // Check if the item exists in the jira projects and remove it.
      const index = selectedJiraProjects.value.findIndex(elem => elem.id === item.id);
      selectedJiraProjects.value.splice(index, 1);
    }

    const jiraProjects = computed(() => store.getters.getJiraProjects);
    const searchResults = ref([]);

    function search(searchTerm) {
      // If the searchTerm is a non-empty string, we should make a search request.
      if (searchTerm) {
        searchJiraProject(searchTerm).then(response => {
          if (response) {
            // Filter the data without the selectedJiraProjects.
            searchResults.value = jiraProjects.value.filter(item => !item.isInArray(selectedJiraProjects.value));
          }
        });
      } else {
        clearJiraSuggestions();
      }
    }

    /** Jira project **/
    const {searchJiraProject, clearJiraProjects} = getJiraHelpers();

    function clearJiraSuggestions() {
      searchResults.value = [];
      clearJiraProjects();
    }

    /** Order types **/
    const {orderTypes, getOrderTypes} = getOrderHelpers();
    getOrderTypes();

    /** Create project **/
    const isCreateButtonEnabled = computed(() => selectedJiraProjects.value.length > 0 && !isEmptyOrSpaces(projectName.value)); // The button is enabled only if the user has filled in the project name and there are selected jira projects.

    function getDataAndCreateProject() {
      const projects = selectedJiraProjects.value;
      const name = projectName.value;
      createProject({selectedJiraProjects: projects, projectName: name}).then(response => {
        if (response) {
          emit('created', projectName.value);
        }
      });
    }

    function createProject(data) {
      return store.dispatch(CREATE_PROJECT, data);
    }

    return {
      /** Project name **/
      projectName,

      /** Search Jira projects **/
      search,
      selectJiraProject,
      selectedJiraProjects,
      removeJiraProject,
      jiraProjects,
      searchResults,

      /** Create project **/
      isCreateButtonEnabled,
      getDataAndCreateProject,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/forms";
@import "../../../assets/css/layout.shared";

// Selected projects
.selected-projects {
  margin-top: rem(16);
}

.no-selected-projects {
  color: var(--gray-light);
  font-family: "Open Sans";
  font-size: rem(16);
  line-height: rem(22);
  margin-top: rem(18);
}

.project-item {
  &:not(:last-child) {
    margin-bottom: rem(8);
  }
}
</style>
