<template>
  <div>
    <h1 v-if="userProfile" class="main-title title">Hi <span class="username">{{ userProfile.firstName }}</span>, welcome to Billy</h1>

    <NoRecordsYet @create="$emit('create')"
                  :button-icon="require('../../../assets/icons/svg/ic_add_white.svg')"
                  title="Get started on your first project"
                  text="Add your first project and start managing your hour logs."
                  button-text="Create project"/>
  </div>
</template>

<script>
import NoRecordsYet from "../NoRecordsYet";
import {computed} from "@vue/composition-api";

export default {
  name: "NoProjectsYet",
  components: {
    NoRecordsYet,
  },
  emits: ['create'],
  setup(_, {root}) {
    const store = root.$store;

    /** User **/
    const userProfile = computed(() => store.getters.getUserProfile);

    return {
      /** User **/
      userProfile
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.username {
  color: var(--red-main);
}

.title {
  margin-bottom: rem(60);
}
</style>
